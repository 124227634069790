<template>
  <div id="page_not_found" class="bg-white text-color-white">
    <section class="py35 px20 page_not_found">
      <div class="container">
        <div class="title">
          {{ $t("404") }}
        </div>
        <div class="content">
          <span>
            {{ $t("Page not found. The page you are looking for was moved, removed, renamed or may never have existed.") }}
          </span>
        </div>
        <router-link :to="localizedRoute('/')" class="action cl-secondary no-underline">
          {{ $t('VISIT OUR HOMEPAGE') }}
          <i class="fa fa-arrow-right"></i>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { Logger } from '@vue-storefront/core/lib/logger'
export default {
  name: 'PageNotFound',
  async asyncData ({ store, route, context }) {
    Logger.log('Entering asyncData for PageNotFound ' + new Date())()
    if (context) {
      context.output.cacheTags.add(`page-not-found`)
      context.server.response.statusCode = 404
    }
  }
}
</script>
